import React from 'react'
import { graphql} from 'gatsby'
import styled from '@emotion/styled'
import {FormattedMessage} from 'react-intl';
import Vimeo from '@u-wave/react-vimeo'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import RenderBlock from '../../components/blocks/RenderBlock'
import Button from '../../components/common/Button'

import Image1 from '../../assets/images/landing-left1.png'

const urls = {
  en: "/enpurr-oudlycanadian/",
  zh_hans: "/zh-hans/purr-oudlycanadian/",
  zh_hant: "/zh-hant/purr-oudlycanadian/"
}
const metas = {
  description: "Bathroom tissue. Paper towel. Facial tissue. Napkins. ROYALE® has products for every room in your home. Explore our wide range of products today!",
  keywords: "",
  title: "Quality household paper products you can count on | ROYALE®"
}

const BlueHeader = styled.div`
  width: 100%;
  background: #00aeef;
  padding: 50px 0;
  margin: -14px 0 25px;
  z-index: 10;
  position: relative;
  p {
    width: 88%;
    max-width: 1250px;
    margin: 0 auto;
    color: white;
    font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
    line-height: 30pt;
    font-size: 20pt;
  }
  @media(max-width: 850px){
    p {
      width: 88%;
      line-height: 19pt;
      font-size: 12pt;
    }
  }
`

const BlueFooter = styled.div`
  width: 100%;
  background: #00aeef;
  padding: 50px 5%;
  display: flex;
  align-items:center;
  text-align: center;
  > div {
    color: white;
    font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
    line-height: 18pt;
    font-size: 12pt;
    h2 {
      line-height: 28pt;
    }
    img {
      width: 300px;
      margin: 0 auto;
    }
    width: 50%;
    :last-child {
      text-align: left;
      max-width: 400px
    }
  }
  @media(max-width: 850px){
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

const ButtonLink = styled(Button)`
  background: transparent;
  display: inline-block;
  color: white;
  font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
  padding: 10px 35px;
  border: 2px solid white;
  text-decoration: none;
  border-radius: 30px;
  margin-top: 25px;
`

const VideoBlockWrapper = styled.div`
  & > div > iframe {
    width: 100%;
  }
`

class Products extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      videoSize: 0,
    }
  }

  componentDidMount() {
    let newSize = 595
    newSize = window.innerWidth * 57 / 100
    this.setState({videoSize: newSize})
  }

  render(){

    const { data } = this.props
    const { videoSize } = this.state

    return (
      <Layout
        meta={metas}
        urls={urls}
        locale='en'
      >
        <Header
          urls={urls}
          locale='en'
        />
        { videoSize ?
          (
            <VideoBlockWrapper>
              <Vimeo
                video="https://vimeo.com/436414068"
                height={videoSize}
              />
            </VideoBlockWrapper>
          )
          :
          null
        }
        <BlueHeader>
          <FormattedMessage id='landingpage.bluetop'>
            {(txt) => (
              <p dangerouslySetInnerHTML={{__html: txt}} />
            )}
          </FormattedMessage>
        </BlueHeader>
        <RenderBlock blocks={data.defaultPage.blocks} />
        <BlueFooter>
          <div>
            <img src={Image1} alt="Tissue box" />
          </div>
          <div>
            <h2><FormattedMessage id="landingpage.save" /></h2>
            <ButtonLink to="https://share.promotionpod.com/share/9746f7a80e3256f87de333aeac5b6d12cceca3e8/" target="_blank"><FormattedMessage id="landingpage.coupon" /></ButtonLink>
          </div>
        </BlueFooter>
        <Footer />
      </Layout>
    )
  }


}

export const query = graphql`
query {
  defaultPage(guid: {eq: "08421dc2-480c-4c99-b6d5-834990b6fe7f"}, locale: {eq:"en"}) {
    title_html
    subtitle
    description
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLandingBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Products
